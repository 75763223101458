<template>
    <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-200"
    
    >
      <div class="rounded-t mb-0 px-4 py-3 border-0 bg-teal-400">
        <div class="flex flex-wrap items-center ">
          <div class="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3
              class="font-semibold text-lg text-black "
              
            >
              INVOICE LISTS
            </h3>
          </div>
        
        </div>
      </div>
      <div class="block w-full overflow-x-auto">
        <!-- Projects table -->
        <table class="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-600 "
                
              >
                Invoice Date
              </th>
              <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-600 "
                
              >
                Invoices File
              </th>
              <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left bg-blueGray-600 "
                
              >
               Description
              </th>
  
              <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-600 "
                
              >Amount
            </th>
            
            <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-600 "
                
              >Action
            </th>
            <th
                class="text-white px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center bg-blueGray-600 "
                
              >Admin File
            </th>
             
            </tr>
             
          </thead>
          <tbody>
            <tr v-for="invoice in invoices" :key="invoice.id" class="border-b border-gray-300" >
              <td class="px-6 align-middle   py-3 text-xs whitespace-nowrap font-semibold text-left">
                {{ formatToYmd(invoice.date_purchased) }}
              </td>
              <td class="px-6 align-middle   py-3 text-sm whitespace-nowrap font-semibold text-left">
                <div v-if="invoice.invoice_file">
                <button @click="openFileSwal(invoice.invoice_file, getFileNameFromUrl(invoice.invoice_file))" 
                class="bg-teal-200  m-2"
                >
                  {{ getFileNameFromUrl(invoice.invoice_file)}}
                  
                </button>
              </div>
              <div v-else>
                No invoice available.
              </div>
              </td>
              <td class="px-6 align-middle   py-3 text-sm whitespace-nowrap font-semibold text-left">
                {{ invoice.description }}
              </td>
              <td class="px-6 align-middle   py-3 text-xs whitespace-nowrap font-semibold text-left">
                {{ invoice.invoice_price }}
              </td>
              <td class="px-6 align-middle   py-3 text-xs whitespace-nowrap font-semibold text-left">
                <div v-if="invoice.is_client_approved == 0">
                  <button
                @click="approveInvoice(invoice.id)" 
                class="bg-lightBlue-400 text-black active:bg-blueGray-600 text-sm font-bold uppercase px-3 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1  ease-linear transition-all duration-150">
                  Approved
                </button>
                <button 
                 @click="declineInvoice(invoice.id)"
                class="bg-red-600 text-black active:bg-blueGray-600 text-sm font-bold uppercase px-3 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1  ease-linear transition-all duration-150">
                  Decline
                </button>
                </div>
                <div v-else>
                  <span v-if="invoice.is_client_approved == 1" class="text-green-600">Approved</span><span v-else class="text-red-600">Declined"></span>
                </div>
              </td>
              <td class="px-6 align-middle   py-3 text-sm whitespace-nowrap font-semibold text-left">
               
                  <div v-if="invoice.reciept_file">
                    <button @click="openFileSwal(invoice.reciept_file, getFileNameFromUrl(invoice.reciept_file))" class="bg-teal-200  m-1">
                      {{ getFileNameFromUrl(invoice.reciept_file) }}
                    </button>
             
                </div>
                
                <div v-else>
                  <div v-if="invoice.is_client_approved == 1">Waiting for Admin to process payment</div>
                  <div v-if="invoice.is_client_approved == 0">Waiting for your confirmation</div>
                  <div v-if="invoice.is_client_approved <0">Declined Invoice</div>
                </div>
                
              </td>
              
            </tr>
           
          </tbody>
        </table>
      </div>
     
    </div>
  </template>
  <script>
   import axios from 'axios';
   import Swal from 'sweetalert2/dist/sweetalert2.js';
   import 'sweetalert2/dist/sweetalert2.min.css';
   import $ from 'jquery';
   export default {
        data()
        {
           return {
            isModalOpen: false,
            invoices:[]
           }
        },
        methods: {
          getFileNameFromUrl(url) {
            // Extracts the file name from a URL
            const parts = url.split('/');
            return parts[parts.length - 1];
          },
            openModal() {
            this.isModalOpen = true;
            },
            closeModal() {
              this.fetchPayables();
            this.isModalOpen = false;
            },
            async fetchPayables()
            {
              try {
                const token = localStorage.getItem('token');
                const response = await axios.post(`${process.env.VUE_APP_BACKEND_DOMAIN}/api/client_fetch_vendors_invoice`,[], {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'multipart/form-data',
                },
              });
              this.invoices = response.data.data; 
              
               
              } catch (error) {
                // Handle errors
                console.error("Backend error:", error);
              }
            },
            formatToYmd(dateTimeString) {
              const date = new Date(dateTimeString);
              const year = date.getUTCFullYear();
              const month = String(date.getUTCMonth() + 1).padStart(2, '0');
              const day = String(date.getUTCDate()).padStart(2, '0');
              return `${year}-${month}-${day}`;
            },
            openFileSwal(file_data, filename) {
                Swal.fire({
                  title: filename,
                  html: this.getHtmlContent(file_data, filename),
                  showCloseButton: true,
                  showCancelButton: false,
                  showConfirmButton:false,
                  focusConfirm: false,
                });
            },
            getHtmlContent(file_data, file_name) {
              let content = '';
              // var encodedFileName = encodeURIComponent(file_name);
              var btn_class ="bg-lightBlue-600 text-white text-sm font-bold uppercase px-6 py-3 rounded shadow outline-none focus:outline-none mr-1 mb-1 w-half ease-linear transition-all duration-150 btn_download";
              if (this.isImage(file_name)) 
              {
                content += `<img src="${file_data}" style="max-width: 100%; max-height: 100%;">`;
                content += `<br><button class="${btn_class}" data-url="${file_data}" data-filename="${file_name}">Download</button>`;
              } 
              else if (this.isExcel(file_name)) 
              {
                content += `Please Download The file to view: <a class="bg-teal-500" href="${file_data}" download="${file_name}">Click here to download the file</a>`;
              } 
              else 
              {
                content += "Unsupported file type";
              }

              return content;
            },
            isImage(fileName) {
              const imageExtensions = [".png", ".jpg", ".jpeg", ".gif", ".bmp", ".pdf"];
              return imageExtensions.some(ext => fileName.toLowerCase().endsWith(ext));
            },
            isExcel(fileName) {
              // Check if the MIME type indicates an Excel file
              return fileName.toLowerCase().endsWith(".xlsx");
            },
            async approveInvoice(invoiceId) {
              const confirmation = await Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to approve this invoice?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, approve it!',
                cancelButtonText: 'Cancel',
              });

              if (confirmation.isConfirmed) {
                try {
                  const token = localStorage.getItem('token');
                  const response = await axios.post(
                    `${process.env.VUE_APP_BACKEND_DOMAIN}/api/clientApproveInvoice`,
                    { invoice_id: invoiceId },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );
                  if (response.status === 200) {
                    Swal.fire({
                      icon: 'success',
                      title: 'Invoice Approved',
                      text: 'The invoice has been successfully approved.',
                    });
                    this.fetchPayables(); // Refresh the invoice list
                  } else {
                    Swal.fire({
                      icon: 'error',
                      title: 'Approval Failed',
                      text: response.data.message || 'An error occurred while approving the invoice.',
                    });
                  }
                } catch (error) {
                  console.error('Error approving invoice:', error);
                  Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to approve the invoice. Please try again later.',
                  });
                }
              }
            },

            async declineInvoice(invoiceId) {
              const confirmation = await Swal.fire({
                title: 'Are you sure?',
                text: 'Do you want to decline this invoice?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, decline it!',
                cancelButtonText: 'Cancel',
              });

              if (confirmation.isConfirmed) {
                try {
                  const token = localStorage.getItem('token');
                  const response = await axios.post(
                    `${process.env.VUE_APP_BACKEND_DOMAIN}/api/clientDeclineInvoice`,
                    { invoice_id: invoiceId },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );
                  if (response.status === 200) {
                    Swal.fire({
                      icon: 'success',
                      title: 'Invoice Declined',
                      text: 'The invoice has been successfully declined.',
                    });
                    this.fetchPayables(); // Refresh the invoice list
                  } else {
                    Swal.fire({
                      icon: 'error',
                      title: 'Decline Failed',
                      text: response.data.message || 'An error occurred while declining the invoice.',
                    });
                  }
                } catch (error) {
                  console.error('Error declining invoice:', error);
                  Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Failed to decline the invoice. Please try again later.',
                  });
                }
              }
            },
        },
        mounted() {
          this.fetchPayables();
        },
   }
   $(document).on('click', '.btn_download', function(){
        var url = $(this).data('url');
        var filename = $(this).data('filename');
        getImageBlobFromS3(url,filename);
    })
    function getImageBlobFromS3(url,fileName) 
    {
        $.ajax({
            url: url,
            type: 'GET',
            xhrFields: {
                responseType: 'blob' 
            },
            success: function(data) {
                downloadBlob(data,fileName);
            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.error('Error fetching image:', errorThrown);
            }
        });
        function downloadBlob(blob, fileName) 
            {
            var a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = fileName;
            document.body.appendChild(a);
            a.click(); 
            document.body.removeChild(a);
            }   
    }
  </script>